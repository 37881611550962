/*global document,OK,require*/

OK.hooks = {};

(function () {
    OK.hooks.StickyBannerHookFactory = function (ua) {
        if (!ua.mobile) {
            return new Promise(function (resolve) {
                require(['OK/StickyBannerHook'], function (_) {
                    resolve(new _.StickyBannerHook());
                });
            });
        } else {
            return null;
        }
    };

    OK.hooks.StickyPanelHookFactory = function (ua) {
        if (!ua.mobile) {
            return new Promise(function (resolve) {
                require(['OK/StickyPanelHook'], function (_) {
                    resolve(new _.StickyPanelHook());
                });
            });
        } else {
            return null;
        }
    };

    OK.hooks.PSStickyFiltersHookFactory = function (ua) {
        if (!ua.mobile) {
            return new Promise(function (resolve) {
                require(['OK/PSStickyFiltersHook'], function (_) {
                    resolve(new _.PSStickyFiltersHook());
                });
            });
        } else {
            return null;
        }
    };

    OK.hooks.FriendsStreamHookFactory = function (ua) {
        if (!ua.mobile) {
            return new Promise(function (resolve) {
                require(['OK/FriendsStream'], function (_) {
                    resolve(new _.FriendsStream());
                });
            });
        } else {
            return null;
        }
    };

    require(["OK/NativeHooksReady"], function (module) {
        module.nativeHooksReady();
    })
}());
